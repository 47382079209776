import React from 'react';
import Text from '../UI/Text';

const Footer = () => {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row mtn-40">
          <div className="col-lg-12">
            <Text classes="copyright-txt">&copy; {new Date().getFullYear()} R-WORKS All Rights Reserved.</Text>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
