import React from 'react';
import SectionTitle from '../UI/SectionTitle';
import ServiceItem from './ServiceItem';
import ServicesData from '../../data/Services/services';

import serviceTopBg from '../../assets/img/service/service-bg.jpg';

function Services({ classes }) {
  return (
    <div id="services" className={`service-area-wrapper ${classes}`}>
      <div className="service-area-top" style={{ backgroundImage: `url("${serviceTopBg}")` }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-5 m-auto text-center">
              <SectionTitle variant="light" title="Services" heading="" />
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-12 m-auto text-center">
              <p style={{ color: 'white' }}>
                The pandemic accelerated the need for any business to have a digital footprint and selling their
                products and services on the internet. <br /> We are here to guide and support you on this digital
                journey.
              </p>
            </div>
          </div>
        </div>

        <div className="container" style={{ marginTop: '3rem' }}>
          <div className="row mtn-30">
            {ServicesData.map((service) => (
              <ServiceItem
                key={service.id}
                id={service.id}
                title={service.title}
                text={service.shortDesc}
                thumb={service.thumb}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
