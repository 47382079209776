import React, { useEffect, useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { loadCaptchaEnginge, validateCaptcha, LoadCanvasTemplateNoReload } from 'react-simple-captcha';

import FormInput from '../../components/UI/Input';

const From = () => {
  const [sent, isSent] = useState(false);
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);
  const form = useRef();

  const [captchaError, setCaptchaError] = useState(false);

  const onSubmit = (e) => {
    let user_captcha_value = document.getElementById('user_captcha_input').value;
    const captchaValid = validateCaptcha(user_captcha_value);
    e.preventDefault();
    if (!captchaValid) {
      setCaptchaError(true);
    } else {
      console.log(e.target);
      setCaptchaError(false);
      emailjs
        .sendForm('service_4pmxhei', 'template_d8vbbfa', form.current, process.env.REACT_APP_EMAIL_JS_USER_ID)
        .then(
          () => {
            isSent(true);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  if (sent) {
    return (
      <div className="contact-form-wrap">
        <p>
          <b>Thank you for contacting us.</b>
          <br />
          Your message has been successfully sent. We will contact you very soon!
        </p>
      </div>
    );
  }

  return (
    <div className="contact-form-wrap">
      <form ref={form} id="contact-form">
        <div className="row">
          <div className="col-md-6">
            <FormInput tag={'input'} type={'text'} name={'first_name'} placeholder={'First Name *'} />
          </div>

          <div className="col-md-6">
            <FormInput tag={'input'} type={'text'} name={'last_name'} placeholder={'Last Name *'} />
          </div>

          <div className="col-md-6">
            <FormInput tag={'input'} type={'email'} name={'email_address'} placeholder={'Email address *'} />
          </div>

          <div className="col-md-6">
            <FormInput tag={'input'} type={'text'} name={'phone_no'} placeholder={'Phone No'} />
          </div>

          <div className="col-12">
            <FormInput tag={'textarea'} name={'con_message'} placeholder={'Write Your Message *'} />

            <LoadCanvasTemplateNoReload />
            <FormInput
              tag={'input'}
              type={'text'}
              name={'captcha'}
              id={'user_captcha_input'}
              placeholder={'Enter Captcha'}
            />

            <FormInput tag={'button'} classes={'btn-outline'} onClick={onSubmit} />

            {captchaError && (
              <div className="alert">
                <p>Captcha Does Not Match.</p>
              </div>
            )}

            <div className="form-message" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default From;
