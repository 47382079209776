import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import logo from '../../assets/img/r-works-logo-white.png';

class Logo extends Component {
  render() {
    return (
      <div className="logo-area">
        <Link to="/">
          <span>
            <img src={logo} alt="Businex-Logo" />
          </span>
        </Link>
      </div>
    );
  }
}

export default Logo;
