import React from 'react';

const HeaderConfig = () => {
  const MobileMenuHandler = () => {
    const offCanvasMenu = document.querySelector('.off-canvas-menu');
    offCanvasMenu.classList.add('active');
  };

  return (
    <div className="header-action mt-lg-3 text-right">
      <button onClick={MobileMenuHandler} className="btn-menu d-lg-none">
        <i className="fa fa-bars" />
      </button>
    </div>
  );
};

export default HeaderConfig;
