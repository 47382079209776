import React from 'react';

function ServiceItem(props) {
  return (
    <div className="col-sm-6 col-lg-4">
      <div className="service-item">
        <figure className="service-thumb">
          <img src={require('../../assets/img/' + props.thumb)} alt={props.title} />

          <figcaption className="service-txt">
            <h5>{props.title}</h5>
          </figcaption>
        </figure>
        <div className="service-content">
          <div className="service-content-inner">
            <h4>{props.title}</h4>
            <p>{props.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceItem;
