import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { useWindowWidth } from '@react-hook/window-size';

import navbarData from '../../../data/Navbar/navbar';

const NavbarItem = () => {
  const onlyWidth = useWindowWidth();
  const isMobile = onlyWidth <= 480;

  const scrollTo = () => {
    const offCanvasMenu = document.querySelector('.off-canvas-menu');
    offCanvasMenu.classList.remove('active');
  };

  return navbarData.map((item) => (
    <li key={item.id}>
      <Link
        onClick={scrollTo}
        to={`${item.link}`}
        spy={true}
        smooth={true}
        offset={isMobile ? -150 : -70}
        duration={500}
      >
        {item.title}
      </Link>
    </li>
  ));
};

export default NavbarItem;
