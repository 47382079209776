import React, { Fragment } from 'react';

import Header from '../components/Header';
import Slider from '../components/Slider/home-one';
import About from '../components/About/home-one';
import Services from '../components/Services';
import Consulting from '../components/Consulting';
import ContactPage from '../templates/Contact';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';

const HomeOne = () => {
  return (
    <Fragment>
      <Header />
      <Slider />
      <About />
      <Services classes="sm-top-wt" />
      <Consulting classes="sm-top-wt" />
      <ContactPage />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default HomeOne;
