import React from 'react';

const FormInput = ({ tag, type, name, id, placeholder, classes, onClick }) => {
  return (
    <div className="single-input-item">
      <label>
        {(() => {
          if (tag === 'input') {
            return <input type={type} name={name} id={id} placeholder={placeholder} className={classes} />;
          } else if (tag === 'textarea') {
            return (
              <textarea name={name} cols="30" rows="7" id={id} placeholder={placeholder} className={classes} required />
            );
          } else if (tag === 'button') {
            return (
              <button className={`btn-outline ${classes}`} onClick={onClick}>
                Send Message
              </button>
            );
          }
        })()}
      </label>
    </div>
  );
};

export default FormInput;
